import template from './events.html';
import style from './events.less';

export default angular.module('eventix.shop.events', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.events', {
            url: '/events',
            views: {
                'shop': {
                    controller: 'EventsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('EventsController', function(UIMessages, shop, order, $interval, $scope, $state) {
        const vm = this;
        vm.order = order;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.events = order.events;

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        if(vm.shop.event_selection === 'disabled' || (vm.availableEvents < 5 && vm.shop.event_selection === 'auto'))
            $state.go('eventix.shop.tickets', { shopId: vm.shop.guid });
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));
    }).name;
